import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CouponTypes } from '../enums/CouponTypesEnum'
import Coupon from '../interfaces/coupon'
import { retrieveFromSessionStorage, saveToSessionStorage } from '../utils/utils'
import TopBar from "../components/common/TopBar";
import CardTitle from "../components/common/CardTitle";
import ClickableCardItem from "../components/common/ClickableCardItem";
import TrialService from "../services/trialService";
import {PaymentStatusEnum} from "../enums/PaymentStatusEnum";
import {FirebaseCleverTabRecordEvent} from "../firebase";
import {EventNames} from "../firebase/EventNames";
import {EventProperties} from "../firebase/EventProperties";
import {ScreenNames} from "../firebase/ScreenNames";

export default function TrialCoupons() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const trialService = useMemo(() => new TrialService(), [])

    const [offers, setOffers] = useState<Coupon[]>([]);
    const [couponCode, setCouponCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fn = async () => {
            setLoading(true);
            const token = searchParams.get("token");
            const trialId = searchParams.get("trialId");
            if (token && trialId) {
                try {
                    saveToSessionStorage("token", token)
                    saveToSessionStorage("trialId", trialId)
                    FirebaseCleverTabRecordEvent(EventNames.OKANE_PAGE_VIEWED, {
                        [EventProperties.PAGE_NAME]: ScreenNames.COUPON_PAGE,
                    });
                    const trialDetail = await trialService.getTrialDetails(Number(trialId));

                    if (trialDetail?.paymentStatus === PaymentStatusEnum.PAID) {
                        navigate("/expired", { replace: true })
                    }
                    if(trialDetail?.houseId){
                        const coupons = await trialService.fetchAllActiveTrialCouponsByHouseId(trialDetail.houseId);
                        const couponData = coupons.filter((eachCoupon) => Number(eachCoupon?.minimumOrderValue) <= Number(trialDetail?.trialAmount));
                        setOffers(couponData);
                    }
                    setLoading(false);
                } catch (err) {
                    navigate("/expired")
                }

            }
        }
        fn()
    }, [navigate, searchParams, trialService])

    const onClickVerify =  async (couponCode: string) => {
        await trialService.verifyCoupon({ couponCode: couponCode }).then((res) =>{
            if(res){
                onCouponClick(res);
            }
        });
    };

    const onCouponClick = (offer: Coupon) => {
        FirebaseCleverTabRecordEvent(EventNames.OKANE_COUPON_APPLIED, {
            [EventProperties.COUPON_TYPE]: offer?.productType,
            [EventProperties.COUPON_AMOUNT]: offer?.couponValue,
        });
        const token = retrieveFromSessionStorage("token");
        const trialId = retrieveFromSessionStorage("trialId");
        navigate(`/trial-pay?token=${token}&trialId=${trialId}&couponCode=${offer?.couponCode}`)
    }

    const onBackClick = () => {
        const token = retrieveFromSessionStorage("token");
        const trialId = retrieveFromSessionStorage("trialId");
        navigate(`/trial-pay?token=${token}&trialId=${trialId}`)
    }

    return (
        <div
            className='d-flex-column-center'
            style={{
                width: '100%',
                height: '100%',
                maxWidth: '500px',
                justifyContent: 'flex-start',
                gap: 10,
            }}>
            <TopBar title={'Apply Coupon'}/>
            <CardTitle title={'Enter the Coupon Code'}/>
            <div style={{width: '100%'}}>
                <input
                    type="text"
                    placeholder={'Enter the coupon code here'}
                    id="coupon-field"
                    name="fname"
                    style={{width: '70%', marginLeft: 16, marginRight: 8, padding: 9}}
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                />
                <button
                    style={{
                        width: '20%',
                        color: 'white',
                        backgroundColor: 'black',
                        borderRadius: '4px',
                        padding: '10px',
                        cursor: 'pointer',
                        border: 'none',
                        marginBottom: 10,
                    }}
                    onClick={() => {onClickVerify(couponCode.trim())}}
                    disabled={couponCode.trim() === ''}
                >
                    APPLY
                </button>
            </div>
            <CardTitle title={'Select from Available Coupons'}/>
            <div
                className='d-flex-column-center'
                style={{
                    width: '95%',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 10
                }}>
                {
                    !!offers && Array.isArray(offers) && offers.length > 0 ?
                        offers.map(offer => {
                        return (
                            <div className="card" key={offer?.couponCode}>
                                <ClickableCardItem
                                    title={`${offer?.couponCode}`}
                                    subtitle={offer.couponType === CouponTypes.FLAT ? `Avail flat Rs. ${offer.couponValue} off` : `Avail flat ${offer.couponValue}% off`}
                                    action={() => onCouponClick(offer)}
                                    actionText={'❯'}
                                />
                            </div>
                        )
                    }) : (!loading ?
                        <div className="card"
                             style={{
                                 alignItems: 'center',
                             }}>
                            <p>No Coupons Available</p>
                            <button
                                style={{
                                    color: 'white',
                                    backgroundColor: 'black',
                                    borderRadius: '9px',
                                    padding: '10px',
                                    cursor: 'pointer',
                                    border: 'none',
                                    marginBottom: 10,
                                }}
                                onClick={onBackClick}
                            >
                                Go Back
                            </button>
                        </div> :
                        ''
                    )
                }
            </div>
        </div>
    )
}
